<template>
  <div class="air-freight">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <div v-if="!schema.annually">
      <Controls
        v-on:add="addUnit()"
        v-on:remove="removeUnit()"
        v-on:update="calculateCarbonEmissions()"
      />
      <div
        class="freight"
        v-for="(item, index) in schema.items"
        :key="`item-${index}`"
      >
        <p v-if="index === 0">Item ID</p>
        <p v-if="index === 0">Origin</p>
        <p v-if="index === 0">Destination</p>
        <p v-if="index === 0">Type</p>
        <p v-if="index === 0">Weight (tonnes)</p>
        <p v-if="index === 0">Distance (kms)</p>
        <p v-if="index === 0">Carbon Emission</p>
        <input
          type="number" step="0.01"
          min="0"
          v-model.number="item.itemId"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <input
          type="message"
          placeholder="Origin"
          v-model="item.origin"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <input
          type="message"
          placeholder="Destination"
          v-model="item.destination"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <select
          name="origin"
          id="origin"
          v-model="item.type"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <option value="" disabled selected>Type</option>
          <template v-for="(type, i) in schema.types">
            <option v-bind:value="type.name" :key="i">
              {{ type.name }}
            </option>
          </template>
        </select>
        <input
          type="number" step="0.01"
          min="0"
          v-model.number="item.weight"
          v-on:change="() => {
            item.weight = item.weight || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
        <input
          type="number" step="0.01"
          min="0"
          v-model.number="item.distance"
          v-on:change="() => {
            item.distance = item.distance || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      <h2>{{ $root.formatNumber(item.tCO2e) }}</h2>
      </div>
      </div>
      <div v-else class="air-freight-totals input-section">
      <div class="air-freight-wrapper">
        <template v-for="input in schema.totalInputs">
          <div class="air-freight-row" :key="input.name">
            <label>{{ input.name }} {{ input.unit }}</label>
            <input
              type="number" step="0.01"
              min="0"
              v-model.number="input.annualTotal"
              v-on:change="() => {
                input.annualTotal = input.annualTotal || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
            />
          </div>
        </template>
      </div>
    </div>

    <div>
      <h2 class="secondary-title">
        Total Carbon Emissions:
        {{ $root.formatNumber(tCO2e) }}
      </h2>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'air-freight-component',
  props: {
    title: String,
    initialSchema: Object,
    schemaName: String,
    item: Object,
    activityID: String,
    identifier: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {},
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          '' +
            process.env.VUE_APP_APIURL +
            'api/calculateScopeThreeAirFreight/',
          {
            schema: this.schema
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    addUnit () {
      var item = {
        itemId: 1,
        origin: '',
        destination: '',
        type: '',
        weight: 0,
        distance: 0,
        eFactorNoRF: 0.0,
        carbonEmissionsNoRF: 0.0,
        eFactorWithRF: 0.0,
        carbonEmissionsWithRF: 0.0
      }

      this.schema.items.push(item)
    },
    removeUnit () {
      if (this.schema.items.length > 1) {
        this.schema.items.pop()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    tCO2e () {
      var tCO2e = 0
      this.schema.emissionSources.forEach(source => {
        if (source.type !== 'WTT') {
          tCO2e += source.tCO2e
        }
      })
      return tCO2e
    }
  }
}
</script>

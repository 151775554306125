<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">Additional Emissions Sources</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <div class="additional-sources">
      <div v-if="!schema.emissionSources.length">
        <div class="input-item long">
          <label>
            Select a subcategory:
          </label>
          <autocomplete placeholder="Select a subcategory" class="search-bar" :search="search"
            :get-result-value="getResultValue" @submit="submit"></autocomplete>
        </div>
      </div>
      <div v-else>
        <div class="custom-source" v-for="(source, i) in schema.emissionSources" :key="`${source.name}-${i}`">
          <Controls v-on:add="addSource()" v-on:remove="removeSource()" v-on:update="saveSources()" />
          <div class="input-item long">
            <label>
              Subcategory:
            </label>
            <autocomplete placeholder="Select a subcategory" :defaultValue="source.subcategory" class="search-bar"
              :search="search" :get-result-value="getResultValue" v-on="{ submit: updateSubcategory(source) }">
            </autocomplete>
          </div>
          <div class="input-item long">
            <label for="annual-total" class="input-title">Name:</label>
            <input type="text" name="name" placeholder="Enter a name" v-model.trim.lazy="source.name"
              v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" v-on:change="saveSources()" />
          </div>
          <div class="input-item long">
            <label for="tCO2e" class="input-title">tCO2e:</label>
            <input :min="0" type="number" step="0.01" name="name" v-model.number="source.tCO2e" v-on:change="() => {
              source.tCO2e = source.tCO2e || 0;
              source.tCO2e ? source.tCO2eMarketRate = source.tCO2e : source.tCO2eMarketRate || 0;
              source.annualTotal = source.tCO2e || 0;
              source.unit = 'tCO2e';
              saveSources()
            }" v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" />
          </div>
          <div class="input-item long">
            <label for="tCO2e" class="input-title">tCO2e Market Rate:</label>
            <input :min="0" type="number" step="0.01" name="name" v-model.number="source.tCO2eMarketRate" v-on:change="() => {
              source.tCO2e = source.tCO2e || 0;
              source.tCO2eMarketRate = source.tCO2eMarketRate || 0;
              source.annualTotal = source.tCO2eMarketRate || 0;
              source.unit = 'tCO2e';
              saveSources()
            }" v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" />
          </div>
          <div v-if="showGases(source)">
            <AdditionalGHGInputs :source="source" />
          </div>
          <div v-if="source.subcategory === 'Fuel & Energy Related Emissions'" class="input-item long">
            <label>Type:</label>
            <select v-on:change="$root.saveData()" name="unit" id="unit-type" v-model="source.type">
              <option value="custom">Custom</option>
              <option value="naturalGasTDL">Natural Gas TDL</option>
              <option value="electricityTDL">Electricity TDL</option>
              <option value="WTT">WTT</option>
            </select>
          </div>
          <Notes :schemaIndex="i" :key="i" :dataSource="source.emissionSourceInfo.dataSource"
            @dataSourceChanged="updateDataSource" :dataQuality="source.emissionSourceInfo.dataQuality"
            @dataQualityChanged="updateDataQuality" :assumptions="source.emissionSourceInfo.assumptions"
            @assumptionsChanged="updateAssumptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Modal from '../modal/Modal.vue'
import Controls from '../controls.vue'
import Notes from '../Notes.vue'
import AdditionalGHGInputs from './AdditionalGHGInputs.vue'

export default {
  name: 'activity-additional-sources',
  components: { Modal, Controls, Notes, AdditionalGHGInputs },
  props: {
    initialSchema: Object,
    scope: String
  },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
    this.cleanSources()
  },
  methods: {
    addGases (source) {
      return source.subcategory === 'Chemical & Industrial Processes'
    },
    showGases (source) {
      const extraGasSubcategories = ['Chemical & Industrial Processes', 'Land use and Land Use changes', 'Mobile Combustion', 'Stationary Combustion']
      return extraGasSubcategories.includes(source.subcategory)
    },
    async cleanSources () {
      let cleaned = false
      this.schema.emissionSources.forEach((source, i) => {
        if (source === null) {
          cleaned = true
          // console.log('cleaning empty emission source')
          this.schema.emissionSources = this.schema.emissionSources.splice(1, i)
        }
      })
      if (cleaned) {
        this.$root.updateMasterSchema(this.schema.emissionSources, this.scope)
        await this.$root.saveData()
        window.location.reload()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.$options.name}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    search (input) {
      let result = this.allSubcategories.filter(cat => cat.subcategory.toLowerCase().includes(input.toLowerCase()))
      // console.log(result)
      return result
    },
    submit (result, i) {
      this.schema.emissionSources.push(result)
      this.saveSources()
    },
    updateSubcategory (source) {
      if (this.addGases(source)) {
        // add gas properties
        source.HFC = source.HFC || 0
        source.PFC = source.PFC || 0
        source.SF6 = source.SF6 || 0
      }
      if (!this.addGases(source)) {
        // remove gas properties
        delete source.HFC
        delete source.PFC
        delete source.SF6
      }
      if (!this.showGases(source)) {
        // remove gas properties
        source.CO2 = 0
        source.CH4 = 0
        source.N2O = 0
      }
      return result => {
        source.category = result.category
        source.subcategory = result.subcategory
        source.GHGScope = result.GHGScope
        source.GHGSubcategory = result.GHGSubcategory
        source.ISOCategory = result.ISOCategory
        this.saveSources()
      }
    },
    addCapitalExpenditure () {
      return {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization',
        subcategory: 'Capital expenditure',
        GHGScope: 3,
        GHGSubcategory: 2,
        ISOCategory: 4,
        name: '',
        type: 'custom',
        unit: '-',
        annualTotal: 0,
        tCO2e: 0,
        tCO2eMarketRate: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    },
    addOtherSubcategory () {
      return {
        category: 'Scope 3 - Category 6: Indirect GHG emissions from other sources',
        subcategory: 'Any other relevant emissions',
        GHGScope: 3,
        GHGSubcategory: 16,
        ISOCategory: 6,
        name: '',
        type: 'custom',
        unit: '-',
        annualTotal: 0,
        tCO2e: 0,
        tCO2eMarketRate: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    },
    getResultValue (result) {
      return result.subcategory
    },
    addSource () {
      this.schema.emissionSources.push({
        category: '',
        subcategory: '',
        GHGScope: '',
        GHGSubcategory: '',
        ISOCategory: '',
        name: '',
        type: 'custom',
        unit: '-',
        annualTotal: 0,
        tCO2e: 0,
        tCO2eMarketRate: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: '',
          assumptions: ''
        },
        marketBasedTotal: false
      })
    },
    removeSource () {
      if (this.schema.emissionSources.length > 0) {
        this.schema.emissionSources.pop()
        this.saveSources()
      }
    },
    saveSources () {
      // console.log(this.scope)
      this.$root.updateMasterSchema(this.schema.emissionSources, this.scope)
      this.$root.saveData()
    },
    updateDataSource (value) {
      // console.log(value)
      this.schema.emissionSources[value.schemaIndex].emissionSourceInfo.dataSource = value.val
    },
    updateDataQuality (value) {
      // console.log(value)
      this.schema.emissionSources[value.schemaIndex].emissionSourceInfo.dataQuality = value.val
    },
    updateAssumptions (value) {
      // console.log(value)
      this.schema.emissionSources[value.schemaIndex].emissionSourceInfo.assumptions = value.val
    }
  },
  computed: {
    allSubcategories () {
      const allSchemas = this.$root.allSchemas
      const allSubcategories = allSchemas.reduce(
        (previousValue, currentValue) => {
          let subCategories = !previousValue.length ? [] : previousValue
          currentValue.emissionSources.forEach(source => {
            if (!subCategories.find(cat => cat.subcategory.toLowerCase() === source.subcategory.toLowerCase())) {
              let subcategory = {
                category: source.category,
                subcategory: source.subcategory,
                GHGScope: source.GHGScope,
                GHGSubcategory: source.GHGSubcategory,
                ISOCategory: source.ISOCategory,
                name: '',
                type: 'custom',
                unit: '-',
                annualTotal: 0,
                tCO2e: 0,
                tCO2eMarketRate: 0,
                CO2: 0,
                CH4: 0,
                N2O: 0,
                emissionSourceInfo: {
                  dataSource: '',
                  dataQuality: 'Please select',
                  assumptions: ''
                }
              }
              if (source.subcategory === 'Purchased Electricity') {
                subcategory.marketBasedTotal = false
              }
              subCategories.push(
                subcategory
              )
            }
          })
          return subCategories
        }
      )
      if (!allSubcategories.find(sub => sub.subcategory === 'Any other relevant emissions')) {
        allSubcategories.push(this.addOtherSubcategory())
      }
      if (!allSubcategories.find(sub => sub.subcategory === 'Capital expenditure')) {
        allSubcategories.push(this.addCapitalExpenditure())
      }
      // add extra gases
      allSubcategories.forEach(sub => {
        if (this.addGases(sub)) {
          // add gas properties
          sub.HFC = 0
          sub.PFC = 0
          sub.SF6 = 0
        }
      })

      return allSubcategories.sort((a, b) => a.subcategory.localeCompare(b.subcategory))
    }
  }

}
</script>

<style></style>

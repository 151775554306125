<template>
  <div class="input-section">
    <div class="activity-title-row">
      <!-- Modal start -->
      <div class="tooltip">
        <h1 class="section-title">{{ title }}</h1>
        <div class="modal-btn" @click="openModal">
          <i class="fas fa-question-circle"></i>
        </div>
      </div>
    </div>
    <!-- Modal end -->
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <h3 class="secondary-title">Electricity consumed (kWh)</h3>
    <div class="controls">
      <!-- v-show="!schema.annually" -->
      <Controls
        v-on:add="addUnit()"
        v-on:remove="removeUnit()"
        v-on:update="calculateCarbonEmissions()"
      />
    </div>
    <div class="units" v-for="(unit, i) in schema.units" :key="`unit-${i}`">
      <div v-if="!schema.annually" class="edit-heading">
        <i class="fad fa-edit"></i>
        <input class="input-item heading" type="text" v-model="unit.title"  v-on:change="calculateCarbonEmissions"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"/>
      </div>
      <!-- <h3 class="secondary-title" v-if="!schema.annually">Unit {{ i + 1 }}</h3> -->
      <div v-if="!schema.annually">
        <div class="input-container-row">
          <div
            class="input-item stacked"
            v-for="index in 4"
            :key="`month-${index}`"
          >
            <label class="month">
              {{ unit.months[index - 1].name }}
            </label>
            <input
              v-on:change="() => {
                unit.months[index - 1].amount = unit.months[index - 1].amount || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              class="unit-text-box"
              v-model.number="unit.months[index - 1].amount"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
        <div class="input-container-row">
          <div
            class="input-item stacked"
            v-for="index in 4"
            :key="`month-${index}`"
          >
            <label class="month">
              {{ unit.months[index + 3].name }}
            </label>
            <input
              v-on:change="() => {
                unit.months[index + 3].amount = unit.months[index + 3].amount || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              class="unit-text-box"
              v-model.number="unit.months[index + 3].amount"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
        <div class="input-container-row">
          <div
            class="input-item stacked"
            v-for="index in 4"
            :key="`month-${index}`"
          >
            <label class="month">
              {{ unit.months[index + 7].name }}
            </label>
            <input
              v-on:change="() => {
                unit.months[index + 7].amount = unit.months[index + 7].amount || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              class="unit-text-box"
              v-model.number="unit.months[index + 7].amount"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
      </div>
      <!-- <div class="total-section"> -->
      <div v-if="!schema.annually" class="input-container-column">
        <h2 class="secondary-title">
          {{ $store.state.app.selectedMonth === '0' ? 'Annual' : 'Month' }} Total: {{ unit.annualTotal }} {{ measurement }}
        </h2>
        <div class="input-container-column">
          <div class="input-item">
            <label>
              % of Building:
            </label>
            <input
              type="number" step="0.01"
              min="0"
              max="100"
              v-model="unit.buildingPercentage"
              v-on:change="() => {
                unit.buildingPercentage = unit.buildingPercentage || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
            />
          </div>
          <h2 class="secondary-title">
            Total: {{ $root.formatNumber(unit.total) }} {{ measurement }}
          </h2>
        </div>
      </div>
      <div v-else>
        <div class="unit" :key="i">
          <div class="edit-heading">
            <i class="fad fa-edit"></i>
            <input class="input-item heading" type="text" v-model="unit.title"  v-on:change="calculateCarbonEmissions"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"/>
          </div>
          <!-- <h3 class="secondary-title">Unit {{ i + 1 }}</h3> -->
          <div class="input-item">
            <label>{{ $store.state.app.selectedMonth === '0' ? 'Annual' : 'Monthly' }} Total:</label>
            <input
              type="number" step="0.01"
              min="0"
              v-model.number="unit.annualTotal"
              v-on:change="() => {
                unit.annualTotal = unit.annualTotal || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
            />
          </div>
          <div class="input-item">
            <label>
              % of Building:
            </label>
            <input
              type="number" step="0.01"
              min="0"
              max="100"
              v-model="unit.buildingPercentage"
              v-on:change="() => {
                unit.buildingPercentage = unit.buildingPercentage || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
            />
          </div>
          <div class="input-item">
            <h2 class="secondary-title">
              Total: {{ $root.formatNumber(unit.total) }}
              {{ measurement }}
            </h2>
          </div>
        </div>
      </div>
      <div class="input-item">
        <label>Country:</label>
        <select
          name="country"
          id="country"
          v-model="unit.selectedCountry"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <!-- <option value="" disabled selected>Select a Country</option> -->
          <template v-for="(country, index) in schema.countries">
            <option v-bind:value="country.name" :key="index">{{
              country.name
            }}</option>
          </template>
        </select>
      </div>
      <div v-if="unit.selectedCountry === 'Other'" class="input-item">
        <label>
          Country Name:
        </label>
        <input
          type="text"
          v-model="unit.country"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
      <div class="input-item">
        <label>
          Electricity is Carbon Neutral:
        </label>
        <input
          type="checkbox"
          v-model="unit.carbonNeutral"
          v-on:change="handleCheck(unit)"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
      <div class="input-item">
        <label>
          Electricity has a Certified Renewable Energy Certificate:
        </label>
        <input
          type="checkbox"
          v-model="unit.certifiedRenewable"
          v-on:change="unit.carbonNeutral = false, calculateCarbonEmissions()"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        />
      </div>
      <!-- v-if="$root.userDetails.admin" -->
      <div class="input-item">
      <label>Market Based Factor:</label>
        <select
          v-model.lazy="unit.marketBasedRate"
          v-on:change="calculateCarbonEmissions()"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
        >
          <option :value="0">None</option>
          <option v-for="ef in marketBasedEFs" :key="ef.id" :value="ef.value">{{
            ef.title
          }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'electricity-component',
  props: {
    title: String,
    initialSchema: Object,
    schemaName: String,
    measurement: String,
    activityID: String,
    identifier: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      annually: true,
      tooltip: '',
      modalOpen: false,
      marketBasedEFs: []
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  async mounted () {
    await this.addMarketBasedEFs()
    if (this.updateTriggered) this.calculateCarbonEmissions()
  },
  methods: {
    async addMarketBasedEFs () {
      let marketBasedEFs = []
      const data = await this.$store.dispatch('getMarketBasedEFs')
      console.log(data)
      let factors = data.nodes
      factors.forEach(factor => {
        marketBasedEFs.push({
          id: factor.id,
          title: factor.title,
          value: factor.variable[0].variableName
        })
      })
      this.marketBasedEFs = marketBasedEFs
    },
    handleCheck (unit) {
      unit.certifiedRenewable = false
      let ecotricity = this.marketBasedEFs.find(ef => ef.title === 'Ecotricity market based EF')
      if (ecotricity) {
        unit.marketBasedRate = ecotricity.value
      }
      this.calculateCarbonEmissions()
    },
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          '' + process.env.VUE_APP_APIURL + 'api/calculateScopeTwoElectricity/',
          {
            schema: this.schema,
            rule: this.rule,
            annually: self.schema.annually
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },

    addUnit () {
      // Add unit value (0) to each unit array in month array
      var unit = {
        title: `Unit ${this.schema.units.length + 1}`,
        months: [
          { name: 'January', amount: 0 },
          { name: 'February', amount: 0 },
          { name: 'March', amount: 0 },
          { name: 'April', amount: 0 },
          { name: 'May', amount: 0 },
          { name: 'June', amount: 0 },
          { name: 'July', amount: 0 },
          { name: 'August', amount: 0 },
          { name: 'September', amount: 0 },
          { name: 'October', amount: 0 },
          { name: 'November', amount: 0 },
          { name: 'December', amount: 0 }
        ],
        selectedCountry: 'New Zealand',
        country: 'New Zealand',
        marketBasedRate: 0,
        carbonNeutral: false,
        certifiedRenewable: false,
        annualTotal: 0,
        buildingPercentage: 100,
        total: 0
      }
      this.schema.units.push(unit)
    },
    removeUnit () {
      if (this.schema.units.length > 1) {
        this.schema.units.pop()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    updateTriggered () {
      return this.$store.state.app.updatedSections.includes(this.schema.title)
    },
    rule () {
      return this.$store.state.app.report.previousElectricityWTTWasMaterial
        ? 'show'
        : 'hide'
    }
  }
}
</script>

<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">Vehicles</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <Controls v-on:add="addVehicle()" v-on:remove="removeVehicle()" v-on:update="calculateCarbonEmissions()" />

    <div class="input-container-row" v-for="n in schema.vehicles.length" :key="n">
      <div v-if="!aviationFuel(schema.vehicles[n - 1].fuelType)">
        <label for="age">Age of Vehicle</label>
        <select name="age" id="age" v-model="schema.vehicles[n - 1].age" v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
          <option v-if="availablePre2010(schema.vehicles[n - 1].fuelType)" value="Pre2010Fleet">Pre-2010</option>
          <option value="2010-2015Fleet">2010-2015</option>
          <option value="Post2015Fleet">Post-2015</option>
          <option value="Post2020Fleet">Post-2020</option>
        </select>
      </div>
      <div>
        <label for="fuel-type">Fuel Type</label>
        <select name="fuel-type" id="fuel-type" v-model="schema.vehicles[n - 1].fuelType" v-on:change="
          setVehicleAge(
            schema.vehicles[n - 1].fuelType,
            schema.vehicles[n - 1].age,
            n - 1
          ),
          setVehicleSize(
            schema.vehicles[n - 1].fuelType,
            schema.vehicles[n - 1].vehicleSize,
            n - 1
          ),
          calculateCarbonEmissions()
          " v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
          <option value="Petrol">Petrol</option>
          <option value="Diesel">Diesel</option>
          <option value="PetrolHybrid">Petrol Hybrid</option>
          <option value="DieselHybrid">Diesel Hybrid</option>
          <option value="PetrolPHEV">Petrol Plug-in Hybrid Vehicle</option>
          <option value="DieselPHEV">Diesel Plug-in Hybrid Vehicle</option>
          <option value="ElectricityPetrolPHEV">Electricity: Petrol Plug-in Hybrid Vehicle</option>
          <option value="ElectricityDieselPHEV">Electricity: Diesel Plug-in Hybrid Vehicle</option>
          <option value="ElectricVehicle">Electric</option>
          <option value="PetrolMotorcycle">Petrol Motorcycle</option>
          <option value="ElectricMotorcycle">Electric Motorcycle</option>

        </select>
      </div>
      <div
        v-if="!aviationFuel(schema.vehicles[n - 1].fuelType) && !schema.vehicles[n - 1].fuelType.includes('Motorcycle')">
        <label for="vehicle-size" class="input-title input-title-left-padding">Vehicle Size</label>
        <select name="vehicle-size" id="vehicle-size" v-model="schema.vehicles[n - 1].vehicleSize"
          v-on:change="calculateCarbonEmissions" v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
          <option value="Micro">Micro &lt; 1350 cc</option>
          <option value="Small">Small 1350 - &lt; 1600 cc</option>
          <option value="Medium">Medium 1600 - &lt; 2000 cc</option>
          <option value="Large">Large 2000 - &lt; 3000 cc</option>
          <option value="XLarge">X-Large > 3000 cc</option>
        </select>
      </div>
      <div
        v-if="!aviationFuel(schema.vehicles[n - 1].fuelType) && schema.vehicles[n - 1].fuelType.includes('Motorcycle')">
        <label for="vehicle-size" class="input-title input-title-left-padding">Vehicle Size</label>
        <select name="vehicle-size" id="vehicle-size" v-model="schema.vehicles[n - 1].vehicleSize"
          v-on:change="calculateCarbonEmissions" v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()">
          <option value="Small"> &lt; 60 cc</option>
          <option value="Large">> 60 cc</option>
        </select>
      </div>
      <div>
        <label for="annual-total" class="input-title input-title-left-padding input-title-bold">{{
          $store.state.app.selectedMonth === '0' ? 'Annual' : 'Monthly' }} Total: ({{ !aviationFuel(schema.vehicles[n -
            1].fuelType) ? 'km' : 'litres' }})</label>
        <input :min="0" type="number" step="0.01" name="annual-total"
          v-model.number="schema.vehicles[n - 1].annualTotal" v-on:change="() => {
            schema.vehicles[n - 1].annualTotal = schema.vehicles[n - 1].annualTotal || 0;
            calculateCarbonEmissions()
          }" v-on:focus="$root.setEditing()" v-on:blur="$root.unsetEditing()" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'
// import calculationData from '../../../../utils/calculationData'

export default {
  name: 'vehicles-component',
  components: { Controls, Modal },
  props: {
    initialSchema: Object,
    activityID: String,
    schemaName: String
  },
  data () {
    return {
      schema: {},
      numberOfVehicles: this.initialSchema.vehicles.length,
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    addVehicle () {
      this.schema.vehicles.push({
        age: 'Post2020Fleet',
        fuelType: 'Petrol',
        vehicleSize: 'Large',
        annualTotal: 0
      })
      this.numberOfVehicles++
    },
    removeVehicle () {
      if (this.numberOfVehicles > 1) {
        // console.log('remove vehicle')
        this.schema.vehicles.pop()
        this.numberOfVehicles--
      }
    },
    validatedSchema () {
      // E-Factor > Size > Fuel > Fleet
      let validatedData = []
      let types = ['kgCO2e', 'kgCO2', 'kgCH4', 'kgN2O', 'WTT'] //       let types = ['kgCO2e', 'kgC02', 'kgCH4', 'kgn2O']

      this.schema.vehicles.forEach(vehicle => {
        let vehicleData = []
        types.forEach(type => {
          // if not aircraft build full varible name
          if (!this.aviationFuel(vehicle.fuelType)) {
            vehicleData.push({
              variableName: `${type}${vehicle.vehicleSize}${vehicle.fuelType}${vehicle.age}`,
              type: type,
              age: vehicle.age,
              fuelType: vehicle.fuelType,
              vehicleSize: vehicle.vehicleSize,
              annualTotal: vehicle.annualTotal
            })
            // if aircraft extra data not needed
          } else {
            vehicleData.push({
              variableName: `${vehicle.fuelType}${type}`,
              type: type,
              age: '',
              fuelType: vehicle.fuelType,
              vehicleSize: '',
              annualTotal: vehicle.annualTotal
            })
          }
        })
        validatedData.push(vehicleData)
      })
      // console.log(validatedData)
      return validatedData
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          '' +
          process.env.VUE_APP_APIURL +
          'api/calculateScopeOneCompanyVehicles',
          {
            key: 'vehicle',
            vehicleData: self.validatedSchema(),
            input: self.schema,
            calculationData: null
          }
        )
        .then(function (response) {
          // handle success
          let total = response.data.result
          self.$emit('calculateTotal', total, self.schemaName)
          self.$root.saveData()
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    availablePre2010 (fuelType) {
      if (
        fuelType === 'Petrol' ||
        fuelType === 'Diesel' ||
        fuelType === 'PetrolHybrid' ||
        fuelType === 'DieselHybrid' ||
        fuelType === 'PetrolMotorcycle'
      ) {
        return true
      } else {
        return false
      }
    },
    setVehicleAge (fuelType, age, n) {
      if (
        (fuelType === 'PetrolPHEV' && age === 'Pre2010Fleet') ||
        (fuelType === 'DieselPHEV' && age === 'Pre2010Fleet') ||
        (fuelType === 'ElectricityPetrolPHEV' && age === 'Pre2010Fleet') ||
        (fuelType === 'ElectricityDieselPHEV' && age === 'Pre2010Fleet') ||
        (fuelType === 'ElectricVehicle' && age === 'Pre2010Fleet') ||
        (fuelType === 'ElectricMotorcycle' && age === 'Pre2010Fleet')
      ) {
        this.schema.vehicles[n].age = '2010-2015Fleet'
      }
    },
    setVehicleSize (fuelType, size, n) {
      if (fuelType.includes('Motorcycle') && (size !== 'Small' || size !== 'Large')) {
        this.schema.vehicles[n].size = 'Small'
      }
    },
    aviationFuel (fuelType) {
      if (fuelType === 'aviationFuelKerosene' || fuelType === 'aviationGas') {
        return true
      }
    }
  }
}
</script>

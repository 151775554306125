import fuelsSchema from './utils/schema/scopeOne/fuelsSchema'
import companyVehiclesSchema from './utils/schema/scopeOne/companyVehiclesSchema'
import airConSchema from './utils/schema/scopeOne/airConSchema'
import industrialProcessesSchema from './utils/schema/scopeOne/industrialProcessesSchema'
import agricultureSchema from './utils/schema/scopeOne/agricultureSchema'

import electricitySchema from './utils/schema/scopeTwo/electricitySchema'

import purchasedGoodsAndServicesSchema from './utils/schema/scopeThree/purchasedGoodsAndServicesSchema'
import inwardFreightSchema from './utils/schema/scopeThree/inwardFreightSchema'
import outwardFreightSchema from './utils/schema/scopeThree/outwardFreightSchema'
import freightNotPaidForSchema from './utils/schema/scopeThree/freightNotPaidForSchema'
import wasteWaterWasteSchema from './utils/schema/scopeThree/wasteWaterWasteSchema'
import businessFlightsSchema from './utils/schema/scopeThree/businessFlightsSchema'
import businessAccommodationSchema from './utils/schema/scopeThree/businessAccommodationSchema'
import businessTravelSchema from './utils/schema/scopeThree/businessTravelSchema'
import staffCommuteSchema from './utils/schema/scopeThree/staffCommuteSchema'
import upstreamLeasedAssetsSchema from './utils/schema/scopeThree/upstreamLeasedAssetsSchema'
import processingSoldGoodsSchema from './utils/schema/scopeThree/processingSoldGoodsSchema'
import useOfSoldGoodsSchema from './utils/schema/scopeThree/useOfSoldGoodsSchema'
import endOfLifeDisposalSchema from './utils/schema/scopeThree/endOfLifeDisposalSchema'
import downstreamLeasedAssetsSchema from './utils/schema/scopeThree/downstreamLeasedAssetsSchema'
import franchiseSchema from './utils/schema/scopeThree/franchiseSchema'
import investmentSchema from './utils/schema/scopeThree/investmentSchema'
import additionalSourcesSchema from './utils/schema/custom/additionalSourcesSchema'

export default {
  defaultSchema: {
    scopeOneSchemas: [
      fuelsSchema,
      companyVehiclesSchema,
      industrialProcessesSchema,
      airConSchema,
      agricultureSchema
    ],
    scopeTwoSchemas: [electricitySchema],
    scopeThreeSchemas: [
      purchasedGoodsAndServicesSchema,
      inwardFreightSchema,
      outwardFreightSchema,
      wasteWaterWasteSchema,
      businessFlightsSchema,
      businessAccommodationSchema,
      businessTravelSchema,
      staffCommuteSchema,
      upstreamLeasedAssetsSchema,
      processingSoldGoodsSchema,
      useOfSoldGoodsSchema,
      endOfLifeDisposalSchema,
      downstreamLeasedAssetsSchema,
      franchiseSchema,
      investmentSchema,
      freightNotPaidForSchema
    ],
    scopeOneSections: [
      'fuels',
      'company-vehicles',
      'industrial-processes',
      'aircon',
      'agriculture'
    ],
    scopeTwoSections: ['electricity'],
    scopeThreeSections: [
      'purchased',
      'inward-freight',
      'outward-freight',
      'waste-water-waste',
      'business-flights',
      'business-accommodation',
      'business-travel',
      'staff-commute',
      'upstream-leased-assets',
      'processing-sold-goods',
      'use-of-sold-goods',
      'end-of-life-disposal',
      'downstream-leased-assets',
      'franchises',
      'investments',
      'freight-not-paid-for'
    ],
    additionalSources: additionalSourcesSchema,
    version: 3.36
  },
  masterSchema: {},
  siteContent: {},
  loaded: false,
  saving: false,
  refreshReports: false,
  loggedIn: false,
  userDetails: {},
  time: Date.now(),
  editing: false,
  editingTimeout: null,
  schemaLoaded: false,
  calendarYearEnd: 2020,
  fuelsAndElectricity: {
    sections: [
      'fuels',
      'electricity'
    ],
    schemas: [
      fuelsSchema,
      electricitySchema
    ]
  },
  travelAndTransport: {
    sections: [
      'company-vehicles',
      'staff-commute',
      'business-flights',
      'business-travel',
      'business-accommodation'
    ],
    schemas: [
      companyVehiclesSchema,
      staffCommuteSchema,
      businessFlightsSchema,
      businessTravelSchema,
      businessAccommodationSchema
    ]
  },
  freight: {
    sections: [
      'inward-freight',
      'outward-freight',
      'freight-not-paid-for'
    ],
    schemas: [
      inwardFreightSchema,
      outwardFreightSchema,
      freightNotPaidForSchema
    ]
  },
  goodsAndServices: {
    sections: [
      'purchased',
      'processing-sold-goods',
      'use-of-sold-goods',
      'end-of-life-disposal'
    ],
    schemas: [
      purchasedGoodsAndServicesSchema,
      processingSoldGoodsSchema,
      useOfSoldGoodsSchema,
      endOfLifeDisposalSchema
    ]
  },
  waste: {
    sections: ['waste-water-waste'],
    schemas: [wasteWaterWasteSchema]
  },
  industryAndAgriculture: {
    sections: ['industrial-processes', 'aircon', 'agriculture'],
    schemas: [industrialProcessesSchema, airConSchema, agricultureSchema]
  },
  assets: {
    sections: ['upstream-leased-assets', 'downstream-leased-assets'],
    schemas: [upstreamLeasedAssetsSchema, downstreamLeasedAssetsSchema]
  },
  financial: {
    sections: ['franchises', 'investments'],
    schemas: [franchiseSchema, investmentSchema]
  },
  additionalSources: {
    sections: ['additional-sources'],
    schemas: [additionalSourcesSchema]
  }
}

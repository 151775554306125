<template>
  <div class="input-section">
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
      <!-- Modal start -->
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <Controls
      v-on:add="addGasType()"
      v-on:remove="removeGasType()"
      v-on:update="calculateCarbonEmissions()"
    />
    <div
      class="input-container-row"
      v-for="n in schema.numberOfGasType"
      :key="n"
    >
      <div>
        <label for="topUpDate">Top Up Date</label>
        <input
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          v-model="schema.gasTypes[n - 1].topUpDate"
          type="date"
          name="topUpDate"
        />
      </div>
      <div>
        <label for="gasType"
          >Gas Type <span class="measurement">(e.g HFC-134a)</span></label
        >
        <select
          name="gas"
          id="gas"
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          v-model="schema.gasTypes[n - 1].gasType"
        >
          <template v-for="(gas, index) in schema.gases">
            <option v-bind:value="gas.name" :key="index">{{ gas.name }}</option>
          </template>
        </select>
      </div>
      <div>
        <label for="topUpAmount">Top Up Amount</label>
        <input
          v-on:change="() => {
            schema.gasTypes[n - 1].topUpAmount = schema.gasTypes[n - 1].topUpAmount || 0;
            calculateCarbonEmissions()
          }"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          v-model.number="schema.gasTypes[n - 1].topUpAmount"
          type="number" step="0.01"
          name="topUpAmount"
          min="0"
        />
      </div>
      <div>
        <label for="unit"
          >Units:
          <span style="text-transform: upperCase">{{
            schema.gasTypes[n - 1].units
          }}</span>
        </label>
      </div>
      <div>
        <label for="contractor"
          >Contractor <span class="measurement">(if known)</span></label
        >
        <input
          v-on:change="calculateCarbonEmissions"
          v-on:focus="$root.setEditing()"
          v-on:blur="$root.unsetEditing()"
          v-model="schema.gasTypes[n - 1].contractor"
          type="message"
          name="contractor"
        />
      </div>
    </div>
    <!-- <div class="total">
      <h4>Total Carbon Emissions:</h4>
      <h4>{{ schema.totalCarbonEmissions }} tCO2e</h4>
    </div> -->
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'chemicals-component',
  props: {
    initialSchema: Object,
    schemaName: String,
    title: String,
    activityID: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },

  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },

  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var schema = JSON.stringify({ schema: this.schema })
      var self = this
      var config = {
        method: 'post',
        url:
          '' + process.env.VUE_APP_APIURL + 'api/calculateScopeOneAirconBasic',
        headers: {
          'Content-Type': 'application/json'
        },
        data: schema
      }
      return axios(config)
        .then(function (response) {
          // console.log('result')
          // console.log(response.data)
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    addGasType () {
      this.schema.gasTypes.push({
        topUpDate: '',
        gasType: 'HFC-134a',
        topUpAmount: 0,
        units: 'kg',
        contractor: '',
        eFactor: 0.0,
        carbonEmissions: 0.0
      })
      this.schema.numberOfGasType++
    },
    removeGasType () {
      if (this.schema.numberOfGasType > 1) {
        this.schema.gasTypes.pop()
        this.schema.numberOfGasType--
        this.calculateCarbonEmissions()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<template>
  <div class="input-section">
    <div class="tooltip">
      <h1 class="section-title">Livestock</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
      <!-- Modal start -->
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->

    <h2 class="secondary-title">Number of livestock (per head)</h2>
    <div class="input-grid col-3">
      <div class="grid-item" v-for="(emission, index) in schema.agricultureCategories[0].emissionSources" :key="`ag-${index}`">
        <div class="input-item stacked">
          <label>
            {{ emission.type }}
          </label>
          <input
            min="0"
            type="number"
            name="numberOfUnits"
            v-model.number="emission.numberOfUnits"
            v-on:change="() => {
              emission.numberOfUnits = emission.numberOfUnits || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'

export default {
  components: {
    Modal
  },
  name: 'agriculture-component',
  props: { initialSchema: Object, schemaName: String, activityID: String },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },

  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },

  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      // Copy number of units across to agricultural soils
      this.schema.agricultureCategories[1].emissionSources[0].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[0].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[1].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[1].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[2].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[2].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[3].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[3].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[4].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[4].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[5].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[5].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[6].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[6].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[7].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[7].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[8].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[8].numberOfUnits
      this.schema.agricultureCategories[1].emissionSources[9].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[9].numberOfUnits

      // Copy Dairy Cows and Chickens to Manure Management
      this.schema.agricultureCategories[2].emissionSources[0].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[0].numberOfUnits
      this.schema.agricultureCategories[2].emissionSources[9].numberOfUnits = this.schema.agricultureCategories[0].emissionSources[9].numberOfUnits

      var schema = JSON.stringify({ schema: this.schema })
      var self = this
      var config = {
        method: 'post',
        url:
          '' + process.env.VUE_APP_APIURL + 'api/calculateScopeOneAgriculture',
        headers: {
          'Content-Type': 'application/json'
        },
        data: schema
      }
      return axios(config)
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.$options.name}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

import updaterCommutingFactorsData from './data/updaterCommutingFactorsData'
import updaterPublicTransportRail from './data/updaterPublicTransportRail'
import updaterContractedServices from './data/updaterContractedServices'
import updaterElectricity from './data/updaterElectricity'
import updaterAviationFuels from './data/updaterAviationFuels'
import updaterAgriculture from './data/updaterAgriculture'
import updaterBiofuels from './data/updaterBiofuels'
import updaterFuelEnergy from './data/updaterFuelEnergy'
import updaterMobileStationaryPetrol from './data/updaterMobileStationaryPetrol'
import updaterBusinessFlights from './data/updaterBusinessFlights'
import updaterEOLOverseasNZ from './data/updaterEOLOverseasNZ'
import Vue from 'vue'
import store from '../../store'

export default function performUpdate (masterSchema) {
  const version = masterSchema.version
  console.log(version, 'version')
  if (version < 3.02) {
    updater('updateFuelEnergyCategories', masterSchema)
  }
  if (version < 3.03) {
    updater('changeWFHCategory', masterSchema)
  }
  if (version < 3.04) {
    updater('addCommutingFactors', masterSchema)
  }
  if (version < 3.05) {
    updater('addPublicTransportRail', masterSchema)
  }
  if (version < 3.06) {
    updater('endOfLifeDisposalCatChange', masterSchema)
  }
  if (version < 3.07) {
    updater('endOfLifeDisposalSubCatChange', masterSchema)
  }
  if (version < 3.08) {
    updater('wasteWaterTreatmentUnit', masterSchema)
  }
  if (version < 3.09) {
    updater('portableWater', masterSchema)
  }
  if (version < 3.10) {
    updater('contractedServicesFuelEnergy', masterSchema)
  }
  if (version < 3.11) {
    updater('updateOutstandingElectricity', masterSchema)
  }
  if (version < 3.12) {
    updater('processingSoldGoodsFuels', masterSchema)
  }
  if (version < 3.13) {
    updater('electricityNaturalGasBreakdown', masterSchema)
  }
  if (version < 3.14) {
    updater('aviationFuels', masterSchema)
  }
  if (version < 3.15) {
    updater('splitFertiliserUseFromAgriculture', masterSchema)
  }
  if (version < 3.16) {
    updater('mobileCombustionESCategory', masterSchema)
  }
  if (version < 3.17) {
    updater('addBiofuel', masterSchema)
  }
  if (version < 3.18) {
    updater('removeFuelEnergy', masterSchema)
  }
  if (version < 3.19) {
    updater('restoreAviationFuels', masterSchema)
  }
  if (version < 3.20) {
    updater('correctElectricityCountries', masterSchema)
  }
  if (version < 3.21) {
    updater('updateDownstreamLeasedAssetsCategories', masterSchema)
  }
  if (version < 3.22) {
    updater('updateLeasedAssetStationaryFuelsUnits', masterSchema)
  }
  if (version < 3.23) {
    updater('updateBusinessFlightsSchema', masterSchema)
  }
  if (version < 3.24) {
    updater('adjustPetrolVariables', masterSchema)
  }
  if (version < 3.25) {
    updater('electricityFactors', masterSchema)
  }
  if (version < 3.26) {
    updater('adjustStationaryCombustionLPGCatgories', masterSchema)
  }
  if (version < 3.27) {
    updater('adjustPetrolVariablesFix', masterSchema)
  }
  if (version < 3.28) {
    updater('fixCompanyVehiclesFuelEnergyAdvanced', masterSchema)
    updater('fixBusinessFlightsFuelEnergyAdvanced', masterSchema)
  }
  if (version < 3.29) {
    updater('adjustStationaryCombustionLPGCatgories', masterSchema)
  }
  if (version < 3.30) {
    updater('fixDomesticBusinessFlightsFuelEnergyAdvanced', masterSchema)
  }
  if (version < 3.31) {
    updater('fixBusinessFlightsFuelEnergyAdvanced', masterSchema)
    updater('fixDomesticBusinessFlightsFuelEnergyAdvanced', masterSchema)
  }
  if (version < 3.32) {
    updater('fixAggregateFactorCategories', masterSchema)
  }
  if (version < 3.33) {
    updater('addEOLOverseasNZ', masterSchema)
  }
  if (version < 3.34) {
    updater('fixOutlierAggregateFactorCategories', masterSchema)
  }
  if (version < 3.35) {
    updater('fixOutlierAggregateFactorCategoriesTwo', masterSchema)
  }
  if (version < 3.36) {
    updater('fixStaffComuteWorkFromHome', masterSchema)
  }
}
function updater (x, masterSchema) {
  let index = ''
  switch (x) {
    case 'updateFuelEnergyCategories':
      console.log('performing update: ' + x)
      let scopes = ['scopeOneSchemas', 'scopeTwoSchemas', 'scopeThreeSchemas']
      // loop through all scopes
      scopes.forEach(scope => {
        masterSchema[scope].forEach(schema => {
          schema.forEach(section => {
            let fuelSources = section.emissionSources.filter(es => es.subcategory === 'Fuel & Energy Related Emissions')
            fuelSources.forEach(fs => {
              fs.category = 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization'
            })
          })
        })
      })
      makeToast('Updated fuel & energy categories', 'success')
      break
    case 'changeWFHCategory':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          // console.log(section)
          if (section.name === 'Staff Working from Home') {
            let WFHSource = section.emissionSources.find(es => es.name === 'Staff Working From Home')
            if (WFHSource) {
              WFHSource.subcategory = 'Staff Commuting'
              WFHSource.GHGSubcategory = 7
              WFHSource.ISOCategory = 3
            }
          }
        })
      })
      makeToast('Updated staff working from home to staff commuting', 'success')
      break
    case 'addCommutingFactors':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.name === 'Staff Vehicle Mileage') {
            section.emissionSources = [...section.emissionSources, ...updaterCommutingFactorsData.staffCommuteEmissionSources]
            section.extraCalculations = [...section.extraCalculations, ...updaterCommutingFactorsData.extraCalculations]
            section.hybridMonths = updaterCommutingFactorsData.monthlyEmissionFactors.hybridMonths
            section.evMonths = updaterCommutingFactorsData.monthlyEmissionFactors.evMonths
            section.phevMonths = updaterCommutingFactorsData.monthlyEmissionFactors.phevMonths
          }
          if (section.name === 'Business Travel Vehicle Mileage') {
            section.emissionSources = [...section.emissionSources, ...updaterCommutingFactorsData.businessTravelEmissionSources]
            section.extraCalculations = [...section.extraCalculations, ...updaterCommutingFactorsData.extraCalculations]
            section.hybridMonths = updaterCommutingFactorsData.monthlyEmissionFactors.hybridMonths
            section.evMonths = updaterCommutingFactorsData.monthlyEmissionFactors.evMonths
            section.phevMonths = updaterCommutingFactorsData.monthlyEmissionFactors.phevMonths
          }
        })
      })
      makeToast('Added commuting factors to staff & business travel vehicle mileage', 'success')
      break
    case 'addPublicTransportRail':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.identifier === 'staff-commute-public-transport') {
            section.emissionSources = [...section.emissionSources, ...updaterPublicTransportRail.staffCommuteEmissionSources]
            section.extraCalculations = [...section.extraCalculations, ...updaterPublicTransportRail.extraCalculations]
            section.railMonths = updaterPublicTransportRail.railMonths
          }
          if (section.identifier === 'business-travel-public-transport') {
            section.emissionSources = [...section.emissionSources, ...updaterPublicTransportRail.businessTravelEmissionSources]
            section.extraCalculations = [...section.extraCalculations, ...updaterPublicTransportRail.extraCalculations]
            section.railMonths = updaterPublicTransportRail.railMonths
          }
        })
      })
      makeToast('Added rail to public transport', 'success')
      break
    case 'endOfLifeDisposalCatChange':
      console.log('performing update: ' + x)
      let EOLSections = []
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        let foundSections = schema.filter(section => {
          let lowerTitle = section.title.toLowerCase()
          return !!lowerTitle.includes('end of life disposal')
        })
        if (foundSections.length) {
          EOLSections = foundSections
        }
      })
      EOLSections.forEach(section => {
        section.emissionSources.forEach(source => {
          if (source.category !== 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization') {
            source.category = 'Scope 3 - Category 5: Indirect GHG emissions associated with the use of products from the organization'
          }
        })
      })
      makeToast('Updated end of life disposal category', 'success')
      break
    case 'endOfLifeDisposalSubCatChange':
      {
        console.log('performing update: ' + x)
        let EOLSections = []
        masterSchema['scopeThreeSchemas'].forEach(schema => {
          let foundSections = schema.filter(section => {
            let lowerTitle = section.title.toLowerCase()
            return !!lowerTitle.includes('end of life disposal')
          })
          if (foundSections.length) {
            EOLSections = foundSections
          }
        })
        EOLSections.forEach(section => {
          section.emissionSources.forEach(source => {
            source.subcategory = 'EOL of Sold Goods'
          })
        })
      }
      makeToast('Updated end of life disposal subcategory', 'success')
      break
    case 'wasteWaterTreatmentUnit':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.identifier === 'waste-water-treatment') {
            section.emissionSources.forEach(source => {
              source.unit = 'M3'
            })
          }
        })
      })
      makeToast('Updated waste water treatment unit to M3', 'success')
      break
    case 'portableWater':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.name === 'Portable Water') {
            // console.log(section.name)
            section.name = 'Potable Water'
          }
        })
      })
      makeToast("Updated 'Portable Water' to 'Potable Water'", 'success')
      break
    case 'contractedServicesFuelEnergy':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.identifier === 'contracted-services') {
            section.variables = updaterContractedServices.variables
          }
        })
      })
      makeToast('Added fuel/energy emissions to contracted services', 'success')
      break
    case 'updateOutstandingElectricity':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Use Of Sold Goods - Electricity') {
            let name = section.emissionSources[0].name.split('-')[0] + 'Electricity - Electricity'
            section.emissionSources[0].name = name
            let units = JSON.parse(JSON.stringify(updaterElectricity.units))
            units[0].annualTotal = section.electricity
            units[0].total = section.electricity
            section.annually = true
            section.units = units
            section.countries = updaterElectricity.countries
            store.state.app.updatedSections.push(section.title)
          } else if (section.title === 'Processing Sold Goods- Electricity') {
            let name = section.emissionSources[0].name.split('-')[0] + 'Electricity - Electricity'
            section.emissionSources[0].name = name
            let units = JSON.parse(JSON.stringify(updaterElectricity.units))
            units[0].annualTotal = section.electricity
            units[0].total = section.electricity
            section.annually = true
            section.units = units
            section.countries = updaterElectricity.countries
            store.state.app.updatedSections.push(section.title)
          }
        })
      })
      makeToast('Updated use/processing of sold goods electricity', 'success')
      break
    case 'processingSoldGoodsFuels':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Processing Sold Goods - Fuels') {
            section.name = 'Stationary Fuels'
            section.title = 'Processing Sold Goods - Stationary Fuels'
            // schema = [...schema, ...updaterProcessingSoldGoods.mobileFuelsSchema]
          }
        })
      })
      makeToast('Updated processing sold goods - fuels', 'success')
      break
    case 'electricityNaturalGasBreakdown':
      console.log('performing update: ' + x)
      let electricitySchema = masterSchema['scopeTwoSchemas'][0][0]
      electricitySchema.countries = updaterElectricity.countries
      store.state.app.updatedSections.push(electricitySchema.title)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Upstream Leased Assets - Electricity' || section.title === 'Electricity Consumption of items Leased to a Third Party') {
            section.countries = updaterElectricity.countries
            store.state.app.updatedSections.push(section.title)
          } else if (section.title === 'Processing Sold Goods - Stationary Fuels' || section.title === 'Use of Sold Goods - Stationary Fuels') {
            section.emissionSources.forEach(source => {
              if (source.name.includes('Natural Gas')) {
                source.unit = 'KWH'
              }
            })
          }
        })
      })
      makeToast('Added electricity countries, changed natural gas unit', 'success')
      break
    case 'aviationFuels':
      console.log('performing update: ' + x)
      masterSchema['scopeOneSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Mobile Combustion - Fuels') {
            console.log(updaterAviationFuels.emissionSources)
            section.emissionSources.splice(3, 0, ...updaterAviationFuels.emissionSources)
            section.emissionSources = [...section.emissionSources, ...updaterAviationFuels.emissionSourcesWTT]
            section.extraCalculations = [...section.extraCalculations, ...updaterAviationFuels.extraCalculations]
            section.aviationGas = updaterAviationFuels.aviationGas
            section.aviationFuelKerosene = updaterAviationFuels.aviationFuelKerosene
          }
        })
      })
      makeToast('Added aviation fuels to mobile combustion', 'success')
      break
    case 'splitFertiliserUseFromAgriculture':
      console.log('performing update: ' + x)
      var fertiliserSchema = updaterAgriculture.fertiliserSchema
      masterSchema.scopeOneSchemas.forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Livestock') {
            // get fertiliser use from agriculture categories
            let fertiliserUse = section.agricultureCategories.find(category => category.title === 'Fertiliser Use')
            // add fertiliser use to fertiliser schema agriculture categories
            fertiliserSchema.agricultureCategories.push(fertiliserUse)
            // get fertilise use emission sources from agriculture
            let fertiliserUseEmissionSources = section.emissionSources.filter(source => source.name.includes('Fertiliser'))
            // add fertiliser use emission sources to fertiliser schema emission sources
            fertiliserSchema.emissionSources.push(...fertiliserUseEmissionSources)
            // remove fertiliser use from agriculture categories
            section.agricultureCategories = section.agricultureCategories.filter(category => category.title !== 'Fertiliser Use')
            // remove fertiliser use emission sources from agriculture emission sources
            section.emissionSources = section.emissionSources.filter(source => !source.name.includes('Fertiliser'))
            // add fertiliser schema to agriculture schema
            schema.push(fertiliserSchema)
          }
        })
      })
      makeToast('Separated fertilser use from agriculture', 'success')
      break
    case 'mobileCombustionESCategory':
      console.log('performing update: ' + x)
      masterSchema.scopeOneSchemas.forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Mobile Combustion - Vehicles') {
            section.emissionSources.forEach(source => {
              if (source.type === 'default') {
                source.GHGScope = 1
                source.GHGSubcategory = '-'
                source.ISOCategory = 1
              }
            })
          }
        })
      }
      )
      makeToast('Updated mobile combustion emission sources', 'success')
      break
    case 'addBiofuel':
      console.log('performing update: ' + x)
      // add biofuel to fuels
      masterSchema['scopeOneSchemas'][0].push(updaterBiofuels.biofuelSources[0])
      masterSchema['scopeOneSchemas'][0].push(updaterBiofuels.biofuelSources[1])

      // add biofuel to mobile combustion
      masterSchema['scopeOneSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Mobile Combustion - Fuels') {
            section.emissionSources.splice(5, 0, ...updaterBiofuels.mobileCombustionEmissionSources)
            section.emissionSources = [...section.emissionSources, ...updaterBiofuels.mobileCombustionEmissionSourcesWTT]
            section.extraCalculations = [...section.extraCalculations, ...updaterBiofuels.extraCalculations]
            section['bioethanol-mc'] = updaterBiofuels.bioethanolMobileCombustion
            section['biodiesel-mc'] = updaterBiofuels.biodieselMobileCombustion
          }
        })
      })
      makeToast('Added biofuel to fuels and mobile combustion', 'success')
      break
    case 'removeFuelEnergy':
      console.log('performing update: ' + x)
      const effectedSchemas = ['Contracted Services and Providers', 'Processing Sold Goods- Electricity', 'Processing Sold Goods - Stationary Fuels', 'Processing Sold Goods - Mobile Fuels', 'Electricity Consumption of items Leased to a Third Party', 'Downstream Leased Assets - Stationary Fuels', 'Downstream Leased Assets - Mobile Fuels', 'Upstream Leased Assets - Electricity', 'Upstream Leased Assets - Stationary Fuels', 'Upstream Leased Assets - Mobile Fuels', 'Use Of Sold Goods - Electricity', 'Use of Sold Goods - Stationary Fuels', 'Use Of Sold Goods - Mobile Fuels']
      const removalTypes = ['WTT', 'electricityTDL', 'naturalGasTDL']
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          // console.log(section.title, 'section title')
          if (effectedSchemas.includes(section.title)) {
            section.emissionSources = section.emissionSources.filter(source => !removalTypes.includes(source.type))
            if (section.identifier === 'stationary-fuels') {
              section.extraCalculations = updaterFuelEnergy.stationaryExtraCalculations
            } else if (section.identifier === 'mobile-fuels') {
              section.extraCalculations = updaterFuelEnergy.mobileExtraCalculations
            } else if (section.identifier.includes('electricity')) {
              delete section.extraCalculations
            } else if (section.identifier === 'contracted-services') {
              section.variables = updaterFuelEnergy.variables
            }
          }
        })
      })
      makeToast('Removed WTT/T&D from scope 3 fuel energy activities', 'success')
      break
    case 'restoreAviationFuels':
      console.log('performing update: ' + x)
      masterSchema['scopeOneSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Mobile Combustion - Fuels') {
            let avgasES = section.emissionSources.find(source => source.id === 'Aviation Gas')
            avgasES.id = 'aviation-gas'
            let avkeroseneES = section.emissionSources.find(source => source.id === 'Aviation Fuel Kerosene')
            avkeroseneES.id = 'aviation-fuel-kerosene'
            section['aviation-gas'] = updaterAviationFuels.aviationGas
            section['aviation-fuel-kerosene'] = updaterAviationFuels.aviationFuelKerosene
            delete section.aviationGas
            delete section.aviationFuelKerosene
          }
        })
      })
      makeToast('Restored aviation fuels within mobile combustion fuels', 'success')
      break
    case 'correctElectricityCountries':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.name.includes('Electricity')) {
            section.countries.forEach(country => {
              if (country.name === 'Argentina') {
                country.CO2e = 'electricityArgentinaKgCO2e'
              }
              if (country.name === 'Malyasia') {
                country.name = 'Malaysia'
              }
            })
          }
        })
      })
      masterSchema['scopeTwoSchemas'][0][0].countries.forEach(country => {
        if (country.name === 'Argentina') {
          country.CO2e = 'electricityArgentinaKgCO2e'
        }
        if (country.name === 'Malyasia') {
          country.name = 'Malaysia'
        }
      })
      makeToast('Electricity - Corrected Malaysia spelling and updated Argentina CO2e EF (requires re-calculation)', 'success')
      break
    case 'updateDownstreamLeasedAssetsCategories':
      console.log('performing update: ' + x)
      index = masterSchema['scopeThreeSections'].findIndex(section => section === 'downstream-leased-assets')
      masterSchema['scopeThreeSchemas'][index].forEach(section => {
        section.emissionSources.forEach(source => {
          source.GHGSubcategory = 13
          source.ISOCategory = 5
        })
      })
      makeToast('Downstream Leased Assets - Updated GHG Subcategory and ISO Category', 'success')
      break
    case 'updateLeasedAssetStationaryFuelsUnits':
      console.log('performing update: ' + x)
      masterSchema['scopeThreeSchemas'].forEach(schema => {
        schema.forEach(section => {
          if (section.title === 'Upstream Leased Assets - Stationary Fuels' || section.title === 'Downstream Leased Assets - Stationary Fuels') {
            console.log(section.title, 'section title')
            let lpg = section.emissionSources.find(source => source.id === 'lpg')
            lpg.unit = 'KG'
            let naturalGas = section.emissionSources.find(source => source.id === 'natural-gas')
            naturalGas.unit = 'KWH'
          }
        })
      })
      makeToast('Leased Asset Stationary Fuels - Updated units', 'success')
      break
    case 'updateBusinessFlightsSchema':
      console.log('performing update: ' + x)
      index = masterSchema['scopeThreeSections'].findIndex(section => section === 'business-flights')
      masterSchema['scopeThreeSchemas'][index].forEach(section => {
        if (section.title === 'Domestic NZ Business Flights') {
          section.classes[0].N2O = 'NZDomesticEconomyN2O'
          section.classes[0].CH4 = 'NZDomesticEconomyCH4'
        }
      })
      makeToast('Business Flights - Updated emission factors CH4/N2O', 'success')
      break
    case 'adjustPetrolVariables':
      console.log('performing update: ' + x)

      // Make adjustments so that default petrol schema becomes premium petrol schema in stationary fuels
      let petrolSchema = masterSchema['scopeOneSchemas'][0][0]
      petrolSchema.name = 'Premium Petrol Consumption (excluding transport)'
      petrolSchema.title = 'Premium Petrol Consumption (excluding transport)'
      petrolSchema.version = 2.78
      petrolSchema.identifier = 'petrol-premium'
      petrolSchema.emissionSources[0].name = 'Stationary Combustion - Petrol (premium)'
      petrolSchema.emissionSources[1].name = 'Stationary Combustion - Petrol (premium) WTT'

      // Add regular petrol schema to stationary fuels
      masterSchema['scopeOneSchemas'][0].unshift(updaterMobileStationaryPetrol.FSPRegularPetrolSchema)

      // Make adjustments so that default petrol becomes premium petrol
      masterSchema['scopeOneSchemas'][1][0].emissionSources[0].name = 'Mobile Combustion - Petrol (premium)'

      // Add new regular petrol emission source to mobile combustion schema
      masterSchema['scopeOneSchemas'][1][0].emissionSources.unshift(updaterMobileStationaryPetrol.TMFEmissionSource)

      // Add new extra calculations for regular petrol WTT to mobile combustion schema
      masterSchema['scopeOneSchemas'][1][0].extraCalculations.unshift(updaterMobileStationaryPetrol.TMFExtraCalculation)

      // Add new variables for regular petrol to mobile combustion schema
      masterSchema['scopeOneSchemas'][1][0]['petrol-regular'] = updaterMobileStationaryPetrol.TMFRegularPetrolVariables

      makeToast('Mobile and Stationary Fuels - Added Regular Petrol & Adjusted Default to Premium Petrol', 'success')
      break
    case 'electricityFactors':
      console.log('performing update: ' + x)
      masterSchema['scopeTwoSchemas'][0][0].version = 2.78
      masterSchema['scopeTwoSchemas'][0][0].countries = updaterElectricity.countries
      makeToast('Updated electricity supply mix countries', 'success')
      break
    case 'adjustStationaryCombustionLPGCatgories':
      console.log('performing update: ' + x)
      masterSchema['scopeOneSchemas'][0][4].emissionSources[1].GHGSubcategory = 3
      masterSchema['scopeOneSchemas'][0][4].emissionSources[1].ISOCategory = 4
      makeToast('Updated stationary combustion LPG categories (again)', 'success')
      break
    case 'adjustPetrolVariablesFix':
      // rename petrol to petrol-premium in mobile combustion schema
      masterSchema['scopeOneSchemas'][1][0]['petrol-premium'] = masterSchema['scopeOneSchemas'][1][0]['petrol']
      delete masterSchema['scopeOneSchemas'][1][0]['petrol']
      const emissionSources = masterSchema['scopeOneSchemas'][1][0].emissionSources
      emissionSources.forEach(source => {
        if (source.id === 'Petrol') {
          source.id = 'petrol-premium'
        }
      })
      makeToast('Mobile and Stationary Fuels - Adjusted Default to Premium Petrol (fix)', 'success')
      break
    case 'fixCompanyVehiclesFuelEnergyAdvanced':
      // rename existing Petrol WTT to Petrol Premium WTT
      const premiumWTTSource = masterSchema['scopeOneSchemas'][1][0]['emissionSources'].find(es => es.name === 'Mobile Combustion - Petrol WTT')
      premiumWTTSource.name = 'Mobile Combustion - Petrol Premium WTT'

      // make and add emission source based on existing premium wtt for regular wtt
      const regularWTTSource = JSON.parse(JSON.stringify(premiumWTTSource))
      regularWTTSource.name = 'Mobile Combustion - Petrol Regular WTT'
      regularWTTSource.id = 'Petrol Regular WTT'
      masterSchema['scopeOneSchemas'][1][0]['emissionSources'].push(regularWTTSource)

      // adjust extra calculations
      masterSchema['scopeOneSchemas'][1][0]['extraCalculations'] = updaterFuelEnergy.extraCalculationsIDAdjustment

      makeToast('Mobile Combustion Fuels - Adjusted extra calculations and more', 'success')
      break
    case 'fixBusinessFlightsFuelEnergyAdvanced':
      masterSchema['scopeThreeSchemas'][4][0]['extraCalculations'] = updaterBusinessFlights.extraCalculations
      makeToast('Int Business Flights - Adjusted extra calculations', 'success')
      break
    case 'fixDomesticBusinessFlightsFuelEnergyAdvanced':
      masterSchema['scopeThreeSchemas'][4][1]['extraCalculations'] = updaterBusinessFlights.extraCalculationsDomestic
      makeToast('Domestic Business Flights - Adjusted extra calculations', 'success')
      break
    case 'addEOLOverseasNZ':
      masterSchema['scopeThreeSchemas'][11].push(updaterEOLOverseasNZ.EOLDisposalNZ)
      masterSchema['scopeThreeSchemas'][11].push(updaterEOLOverseasNZ.EOLDisposalOverseas)
      makeToast('EOL Disposal of Sold Goods - Added Overseas and NZ Disposal', 'success')
      break
    case 'fixAggregateFactorCategories':
      masterSchema['scopeThreeSchemas'][6][3].emissionSources[1].subcategory = 'Fuel & Energy Related Emissions' // Taxi WTT
      masterSchema['scopeThreeSchemas'][6][4].emissionSources[1].subcategory = 'Fuel & Energy Related Emissions' // Rental Cars WTT
      makeToast('EIR Aggregate Factor categorisation fix - Moved emissions sources to correct category', 'success')
      break
    case 'fixOutlierAggregateFactorCategories':
      // fix subcategories
      masterSchema['scopeThreeSchemas'][6][0].emissionSources.find(es => es.name === 'Business Travel - Diesel WTT').subcategory = 'Fuel & Energy Related Emissions'
      masterSchema['scopeThreeSchemas'][6][3].emissionSources.find(es => es.name === 'Business Travel - Taxi WTT').subcategory = 'Fuel & Energy Related Emissions'
      masterSchema['scopeThreeSchemas'][6][5].emissionSources.find(es => es.name === 'Business Travel - Bus WTT').subcategory = 'Fuel & Energy Related Emissions'
      masterSchema['scopeThreeSchemas'][6][5].emissionSources.find(es => es.name === 'Business Travel - Rail WTT').subcategory = 'Fuel & Energy Related Emissions'

      // fix GHG sub category
      masterSchema['scopeThreeSchemas'][6][5].emissionSources.find(es => es.name === 'Business Travel - Bus').GHGSubcategory = 6
      masterSchema['scopeThreeSchemas'][6][5].emissionSources.find(es => es.name === 'Business Travel - Rail').GHGSubcategory = 6
      makeToast('EIR Aggregate Factor categorisation fix (outliers) - Moved emissions sources to correct category', 'success')
      break
    case 'fixOutlierAggregateFactorCategoriesTwo':
      // Fix categories
      masterSchema['scopeThreeSchemas'][7][3].emissionSources[1].subcategory = 'Fuel & Energy Related Emissions'
      masterSchema['scopeThreeSchemas'][7][3].emissionSources[3].subcategory = 'Fuel & Energy Related Emissions'
      // Fix grouping to show in table 6 'Well to tank emissions'
      masterSchema['scopeThreeSchemas'][6][0].emissionSources[3].type = 'WTT'
      masterSchema['scopeThreeSchemas'][6][1].emissionSources[3].type = 'WTT'
      store.state.app.updatedSections.push('Upstream Leased Assets - Electricity')
      makeToast('EIR Aggregate Factor categorisation fix (outliers #2) - Corrected emission source types', 'success')
      break
    case 'fixStaffComuteWorkFromHome':
      masterSchema['scopeThreeSchemas'][7][2].emissionSources[0].id = 'days'
      makeToast('Fixed calculation for Staff Commute - Working from home', 'success')
      break
  }
}
function makeToast (message, type) {
  Vue.prototype.$toast.open({
    message: message,
    type: type,
    queue: true,
    pauseOnHover: true,
    duration: 4000
  })
}

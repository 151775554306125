<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
      <div
      class="controls-container"
      :style="{ 'flex-direction': 'row-reverse', 'margin-top': '10px' }"
    >
      <Controls
        v-on:add="addUnit()"
        v-on:remove="removeUnit()"
        v-on:update="calculateCarbonEmissions()"
      />
    </div>
    <div v-if="type !== 'product'" class="input-section">
      <template  v-for="(unit, i) in schema.units" >
      <div class="units" :key="`general-unit-${i}`">
        <div class="edit-heading">
          <i class="fad fa-edit"></i>
          <input class="input-item heading" type="text" v-model="unit.title"  v-on:change="calculateCarbonEmissions"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"/>
        </div>
        <h3 class="secondary-title">General Waste</h3>
        <div class="input-item">
          <label>Unit</label>
          <select
            name="unit"
            id="unit-type"
            v-model.number="unit.generalType"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <option value="0">kg</option>
            <option value="1">litres</option>
          </select>
        </div>
        <div class="input-item">
          <label>Landfill</label>
          <select
            name="recovery"
            id="recovery-type"
            v-model.number="unit.generalRecovery"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <template v-for="(landfill, i) in landfills">
              <option :key="i" :value="landfill">{{
                landfill.name
              }}</option>
            </template>
          </select>
        </div>
        <div class="input-container-grid" v-if="!schema.annually">
          <div
            class="input-item stacked"
            v-for="(month, index) in unit.months"
            :key="`month-${index - 1}`"
          >
            <label class="month">
              {{ month.name }}
              <span v-if="type === 'product'">{{ index + 1 }}</span>
            </label>
            <input
              v-model.number="month.generalWaste"
              v-on:change="() => {
                month.generalWaste = month.generalWaste || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              class="unit-text-box"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
        <div class="total-section">
          <div v-if="!schema.annually" class="input-container-row">
            <h2 class="secondary-title">
              General Waste:
            </h2>
            <h2 class="secondary-title">
              {{ displayAnnualWaste(unit)[0] }}
            </h2>
          </div>
          <div v-else class="input-container-row" style="margin-top: 10px">
            <h2 class="secondary-title">
              Grand Total:
            </h2>
            <input
              type="number" step="0.01"
              min="0"
              v-model="unit.annualTotalGeneralWaste"
              v-on:change="() => {
                unit.annualTotalGeneralWaste = unit.annualTotalGeneralWaste || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
            />
          </div>
          <div class="input-container-row">
            <h2 class="secondary-title">
              Emissions General Waste:
            </h2>
            <h2 class="secondary-title">
              {{ $root.formatNumber(unit.annualTotalEmissionsGeneralWaste) }}
            </h2>
          </div>
        </div>
      </div>
      <div :key="`margin-unit-${i}`" style="margin-top: 80px;"></div>
      <div :key="`office-unit-${i}`" class="units">
        <h3 class="secondary-title">Office Waste</h3>
        <div class="input-item">
          <label>Unit</label>
          <select
            name="unit"
            id="unit-type"
            v-model.number="unit.officeType"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <option value="0">kg</option>
            <option value="1">litres</option>
          </select>
        </div>
        <div class="input-item">
          <label>Landfill</label>
          <select
            name="recovery"
            id="recovery-type"
            v-model.number="unit.officeRecovery"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
        <template v-for="(landfill, i) in landfills">
              <option :key="i" :value="landfill">{{
                landfill.name
              }}</option>
            </template>
          </select>
        </div>
        <div class="input-container-grid" v-if="!schema.annually">
          <div
            class="input-item stacked"
            v-for="(month, index) in unit.months"
            :key="`month-${index - 1}`"
          >
            <label class="month">
              {{ month.name }}
            </label>

            <input
              v-model.number="month.officeWaste"
              v-on:change="() => {
                month.officeWaste = month.officeWaste || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
              class="unit-text-box"
              type="number" step="0.01"
              min="0"
            />
          </div>
        </div>
        <div class="total-section">
          <div v-if="!schema.annually" class="input-container-row">
            <h2 class="secondary-title">
              Office Waste:
            </h2>
            <h2 class="secondary-title">
              {{ displayAnnualWaste(unit)[1] }}
            </h2>
          </div>
          <div v-else class="input-container-row" style="margin-top: 10px">
            <h2 class="secondary-title">
              Grand Total:
            </h2>
            <input
              type="number" step="0.01"
              min="0"
              v-model="unit.annualTotalOfficeWaste"
              v-on:change="() => {
                unit.annualTotalOfficeWaste = unit.annualTotalOfficeWaste || 0;
                calculateCarbonEmissions()
              }"
              v-on:focus="$root.setEditing()"
              v-on:blur="$root.unsetEditing()"
            />
          </div>
          <div class="input-container-row">
            <h2 class="secondary-title">
              Emissions Office Waste:
            </h2>
            <h2>
              {{ $root.formatNumber(unit.annualTotalEmissionsOfficeWaste) }}
            </h2>
          </div>
        </div>
      </div>
      </template>
    </div>
    <!-- Per Product -->
    <div v-else>
      <template v-for="(unit, i) in schema.units">
        <div :key="`unit-title-${i}`" class="edit-heading">
          <i class="fad fa-edit"></i>
          <input class="input-item heading" type="text" v-model="unit.title"  v-on:change="calculateCarbonEmissions"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"/>
        </div>
          <div :key="`unit-controls-${i}`"
      class="controls-container"
      :style="{ 'flex-direction': 'row', 'margin-top': '10px' }"
    >
      <Controls
        v-show="!schema.annually"
        v-if="type === 'product'"
        v-on:add="addProduct(unit)"
        v-on:remove="removeProduct(unit)"
        v-on:update="calculateCarbonEmissions()"
      />
    </div>
      <div :key="`unit-${i}`" class="input-container-row">
      <!-- General Waste -->
      <div class="input-section">
        <h3 class="secondary-title">General Waste</h3>
        <div class="input-item">
          <label>Unit</label>
          <select
            name="unit"
            id="unit-type"
            v-model.number="unit.generalType"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <option value="0">kg</option>
            <option value="1">litres</option>
          </select>
        </div>
        <div class="input-item">
          <label>Landfill</label>
          <select
            name="recovery"
            id="recovery-type"
            v-model.number="unit.generalRecovery"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
          <template v-for="(landfill, i) in landfills">
              <option :key="i" :value="landfill">{{
                landfill.name
              }}</option>
            </template>
          </select>
        </div>
        <div v-if="!schema.annually" class="units">
          <div class="input-container-column" v-if="!schema.annually">
            <div
              class="input-item stacked"
              v-for="index in unit.months.length"
              :key="`month-${index - 1}`"
            >
              <label class="month">
                {{ unit.months[index - 1].name }}
                <span v-if="type === 'product'">{{ index }}</span>
              </label>
              <input
                v-model.number="unit.months[index - 1].generalWaste"
                v-on:change="() => {
                  unit.months[index - 1].generalWaste = unit.months[index - 1].generalWaste || 0;
                  calculateCarbonEmissions()
                }"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
                class="unit-text-box"
                type="number" step="0.01"
                min="0"
              />
            </div>
          </div>
        </div>
        <div v-else class="input-container-row" style="margin-top: 10px">
          <h2 class="secondary-title">
            Grand Total:
          </h2>
          <input
            type="number" step="0.01"
            min="0"
            v-model="unit.annualTotalGeneralWaste"
            v-on:change="() => {
              unit.annualTotalGeneralWaste = unit.annualTotalGeneralWaste || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
        <div class="input-container-row">
          <h2 class="secondary-title">
            Emissions General Waste:
            {{ $root.formatNumber(unit.annualTotalEmissionsGeneralWaste) }}
          </h2>
        </div>
      </div>
      <!-- Office Waste -->
      <div class="input-section">
        <h3 class="secondary-title">Office Waste</h3>
        <div class="input-item">
                    <label>Unit</label>
          <select
            name="unit"
            id="unit-type"
            v-model.number="unit.officeType"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
            <option value="0">kg</option>
            <option value="1">litres</option>
          </select>
        </div>
        <div class="input-item">
          <label>Landfill</label>
          <select
            name="recovery"
            id="recovery-type"
            v-model.number="unit.officeRecovery"
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          >
      <template v-for="(landfill, i) in landfills">
              <option :key="i" :value="landfill">{{
                landfill.name
              }}</option>
            </template>
          </select>
        </div>
        <div v-if="!schema.annually" class="units">
          <div class="input-container-column" v-if="!schema.annually">
            <div
              class="input-item stacked"
              v-for="index in unit.months.length"
              :key="`month-${index - 1}`"
            >
              <label class="month">
                {{ unit.months[index - 1].name }}
                <span v-if="type === 'product'">{{ index }}</span>
              </label>
              <input
                v-model.number="unit.months[index - 1].officeWaste"
                v-on:change="() => {
                  unit.months[index - 1].officeWaste = unit.months[index - 1].officeWaste || 0;
                  calculateCarbonEmissions()
                }"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
                class="unit-text-box"
                type="number" step="0.01"
                min="0"
              />
            </div>
          </div>
        </div>
        <div v-else class="input-container-row" style="margin-top: 10px">
          <h2 class="secondary-title">
            Grand Total:
          </h2>
          <input
            type="number" step="0.01"
            min="0"
            v-model="unit.annualTotalOfficeWaste"
            v-on:change="() => {
              unit.annualTotalOfficeWaste = unit.annualTotalOfficeWaste || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </div>
        <div class="input-container-row">
          <h2 class="secondary-title">
            Emissions Office Waste:
            {{ $root.formatNumber(unit.annualTotalEmissionsOfficeWaste) }}
          </h2>
        </div>
      </div>
    </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'
import landfills from '../../../../utils/landfills'

export default {
  name: 'landfill-waste-component',
  components: { Controls, Modal },
  props: {
    title: String,
    schemaName: String,
    type: String,
    initialSchema: Object,
    activityID: String,
    identifier: String
  },
  data () {
    return {
      schema: {},
      landfills: landfills,
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    displayAnnualWaste (unit) {
      let annualGeneralWaste = 0
      let annualOfficeWaste = 0
      unit.months.forEach(month => {
        annualGeneralWaste += month.generalWaste ? month.generalWaste : 0
        annualOfficeWaste += month.officeWaste ? month.officeWaste : 0
      })
      return [annualGeneralWaste, annualOfficeWaste]
    },
    addProduct (unit) {
      var item = { name: 'Product', generalWaste: 0, officeWaste: 0 }
      unit.months.push(item)
    },
    removeProduct (unit) {
      if (unit.length > 1) {
        unit.pop()
      }
    },
    addUnit () {
      const unit = {
        title: `Unit ${this.schema.units.length + 1}`,
        generalType: 0,
        officeType: 0,
        officeRecovery: { value: 'without', name: 'Other/Unknown' },
        generalRecovery: { value: 'without', name: 'Other/Unknown' },
        months: [
          { name: 'January', generalWaste: 0, officeWaste: 0 },
          { name: 'February', generalWaste: 0, officeWaste: 0 },
          { name: 'March', generalWaste: 0, officeWaste: 0 },
          { name: 'April', generalWaste: 0, officeWaste: 0 },
          { name: 'May', generalWaste: 0, officeWaste: 0 },
          { name: 'June', generalWaste: 0, officeWaste: 0 },
          { name: 'July', generalWaste: 0, officeWaste: 0 },
          { name: 'August', generalWaste: 0, officeWaste: 0 },
          { name: 'September', generalWaste: 0, officeWaste: 0 },
          { name: 'October', generalWaste: 0, officeWaste: 0 },
          { name: 'November', generalWaste: 0, officeWaste: 0 },
          { name: 'December', generalWaste: 0, officeWaste: 0 }
        ],
        annualTotalGeneralWaste: 0,
        annualTotalEmissionsGeneralWaste: 0,
        annualTotalOfficeWaste: 0,
        annualTotalEmissionsOfficeWaste: 0,
        totalCarbonEmissions: 0.0
      }
      this.schema.units.push(unit)
    },
    removeUnit () {
      if (this.schema.units.length > 1) {
        this.schema.units.pop()
      }
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post('' + process.env.VUE_APP_APIURL + 'api/calculateScopeThreeLandfillWaste', {
          schema: self.schema,
          annually: self.schema.annually
        })
        .then(function (response) {
          // handle success
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
          self.$root.saveData()
          // console.log(response)
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

<template>
  <div class="waste-water-treatment">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <h2 class="secondary-title">Unit: {{this.unit}}</h2>
    <div class="controls">
      <Controls
        v-show="!schema.annually"
        v-on:add="addUnit()"
        v-on:remove="removeUnit()"
        v-on:update="calculateCarbonEmissions()"
      />
    </div>
    <div v-if="!schema.annually">
      <div v-if="type !== 'product'">
        <div
          class="input-section"
          v-for="(unit, i) in schema.units"
          :key="`unit-${i}`"
        >
          <h2 class="secondary-title">Unit {{ i + 1 }}</h2>
          <div class="input-container-grid">
            <!-- <h3>Cubic Metres</h3> -->
            <div
              class="input-item stacked"
              v-for="(month, index) in unit.months"
              :key="`month-${index - 1}`"
            >
              <label class="month">
                {{ month.name }}
                <span v-if="type === 'product'">{{ index + 1 }}</span>
              </label>
              <input
                v-on:change="() => {
                  month.measurement = month.measurement || 0;
                  calculateCarbonEmissions()
                }"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
                v-model.number="month.measurement"
                class="unit-text-box"
                type="number" step="0.01"
                min="0"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div
          class="input-section"
          v-for="(unit, i) in schema.units"
          :key="`unit-${i}`"
        >
          <div class="input-container-column">
            <!-- <h3>Cubic Metres</h3> -->
            <div
              class="input-item stacked"
              v-for="(month, index) in unit.months"
              :key="`month-${index - 1}`"
            >
              <label class="month">
                {{ month.name }}
                <span v-if="type === 'product'">{{ index + 1 }}</span>
              </label>
              <input
                v-on:change="() => {
                  month.measurement = month.measurement || 0;
                  calculateCarbonEmissions()
                }"
                v-on:focus="$root.setEditing()"
                v-on:blur="$root.unsetEditing()"
                v-model.number="month.measurement"
                class="unit-text-box"
                type="number" step="0.01"
                min="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input-section">
      <div v-if="!schema.annually" class="input-container-row">
        <h2 class="secondary-title">
          Grand Total:
        </h2>
        <h2 class="secondary-title">
          {{ schema.emissionSources[0].annualTotal }}
        </h2>
      </div>
      <div v-else class="input-container-row" style="margin-top: 10px">
        <h2 class="secondary-title">
          Grand Total:
        </h2>
        <label>
          <input
            type="number" step="0.01"
            min="0"
            v-model.number="schema.annualGrandTotal"
            v-on:change="() => {
              schema.annualGrandTotal = schema.annualGrandTotal || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
          />
        </label>
      </div>
      <div class="input-container-row">
        <h2 class="secondary-title">
          Total Carbon Emissions:
        </h2>
        <h2 class="secondary-title">
          {{ $root.formatNumber(schema.emissionSources[0].tCO2e) }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import Controls from '../../../controls'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'waste-water-treatment-component',
  components: { Controls, Modal },
  props: {
    title: String,
    unit: String,
    type: String,
    schemaName: String,
    initialSchema: Object,
    activityID: String,
    identifier: String
  },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          `${
            process.env.VUE_APP_APIURL
          }api/calculateScopeThreeWasteWaterEmissions/`,
          {
            schema: self.schema,
            annually: self.schema.annually,
            type: self.schema.type
          }
        )
        .then(function (response) {
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
          self.$root.saveData()
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addUnit () {
      // Add unit value (0) to each unit array in month array
      if (this.type === 'product') {
        this.schema.units[0].months.push({
          name: 'Product',
          measurement: 0
        })
      } else {
        this.schema.units.push({
          months: [
            { name: 'January', measurement: 0 },
            { name: 'February', measurement: 0 },
            { name: 'March', measurement: 0 },
            { name: 'April', measurement: 0 },
            { name: 'May', measurement: 0 },
            { name: 'June', measurement: 0 },
            { name: 'July', measurement: 0 },
            { name: 'August', measurement: 0 },
            { name: 'September', measurement: 0 },
            { name: 'October', measurement: 0 },
            { name: 'November', measurement: 0 },
            { name: 'December', measurement: 0 }
          ],
          annualTotal: 0
        })
      }
    },
    removeUnit () {
      if (this.type === 'product') {
        if (this.schema.units[0].months.length > 1) {
          this.schema.units[0].months.pop()
        }
      } else {
        if (this.schema.units.length > 1) {
          this.schema.units.pop()
        }
      }
    },
    removeAllUnits () {
      if (this.type === 'product') {
        this.schema.units[0] = {
          months: [
            {
              name: 'Product',
              measurement: 0
            },
            {
              name: 'Product',
              measurement: 0
            },
            { name: 'Product', measurement: 0 }
          ],
          annualTotal: 0
        }
      } else {
        this.schema.units = this.schema.units.slice(0, 1)

        this.schema.units[0] = {
          months: [
            { name: 'January', measurement: 0 },
            { name: 'February', measurement: 0 },
            { name: 'March', measurement: 0 },
            { name: 'April', measurement: 0 },
            { name: 'May', measurement: 0 },
            { name: 'June', measurement: 0 },
            { name: 'July', measurement: 0 },
            { name: 'August', measurement: 0 },
            { name: 'September', measurement: 0 },
            { name: 'October', measurement: 0 },
            { name: 'November', measurement: 0 },
            { name: 'December', measurement: 0 }
          ],
          annualTotal: 0
        }
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

// import calculationData from '../../calculationData'

const fuelsSchema = [
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Regular Petrol Consumption (excluding transport)', // Title of Section on front end
    title: 'Regular Petrol Consumption (excluding transport)',
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'petrol-regular', // Main Section component identifier for tooltips
    version: 2.78, // Do I have to increase this?
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Petrol (regular)', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol-regular',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Petrol (regular) WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'petrolRegularWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'kgCO2ePetrol' },
      { name: 'Carbon Dioxide Emissions', variableName: 'kgCO2PetrolRegular' },
      { name: 'Nitros Oxide Emissions', variableName: 'kgN2OPetrolRegular' },
      { name: 'Methane Emissions', variableName: 'kgCH4PetrolRegular' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Premium Petrol Consumption (excluding transport)', // Title of Section on front end
    title: 'Premium Petrol Consumption (excluding transport)',
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'petrol-premium', // Main Section component identifier for tooltips
    version: 2.78,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Petrol (premium)', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'petrol-premium',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Petrol (premium) WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'petrolWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'kgCO2ePetrol' },
      { name: 'Carbon Dioxide Emissions', variableName: 'kgCO2PerUnitPetrol' },
      { name: 'Nitros Oxide Emissions', variableName: 'kgN2OPerUnitPetrol' },
      { name: 'Methane Emissions', variableName: 'kgCH4PerUnitPetrol' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Diesel Consumption (excluding transport)', // Title of Section on front end
    title: 'Diesel Consumption (excluding transport)', // Title of Section on front end
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'diesel', // Main Section component identifier for tooltips
    version: 2.76,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Diesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'diesel',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Diesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'dieselWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'kgCO2eFuelsDiesel' },
      { name: 'Carbon Dioxide Emissions', variableName: 'kgCO2PerUnitDiesel' },
      { name: 'Nitros Oxide Emissions', variableName: 'kgN2OPerUnitDiesel' },
      { name: 'Methane Emissions', variableName: 'kgCH4PerUnitDiesel' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Natural Gas Consumption', // Title of Section on front end
    title: 'Natural Gas Consumption', // Title of Section on front end
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'natural-gas', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Natural Gas', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'natural-gas',
        unit: 'KWH',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Natural Gas WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'KWH',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Natural Gas TDL', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'naturalGasTDL', // default, naturalGasTDL, electricityTDL, WTT
        id: 'naturalGasTDL',
        unit: 'KWH',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'naturalGasWTT' // Main getWellToTankEmissions() variable Name
      },
      {
        type: 'naturalGasTDL',
        variableName: 'naturalGasTDLkWhCO2e'
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Natural Gas Emissions', variableName: 'kgCO2eNaturalGas' },
      {
        name: 'Carbon Dioxide Emissions',
        variableName: 'kgCO2PerUnitNaturalGas'
      },
      {
        name: 'Nitros Oxide Emissions',
        variableName: 'kgN2OPerUnitNaturalGas'
      },
      { name: 'Methane Emissions', variableName: 'kgCH4PerUnitNaturalGas' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'LPG Consumption', // Title of Section on front end
    title: 'LPG Consumption', // Title of Section on front end
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'lpg', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - LPG', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'lpg',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - LPG WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'LPGStationaryWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'kgCO2eLPG' },
      { name: 'Carbon Dioxide Emissions', variableName: 'kgCO2PerUnitLPG' },
      { name: 'Nitros Oxide Emissions', variableName: 'kgN2OPerUnitLPG' },
      { name: 'Methane Emissions', variableName: 'kgCH4PerUnitLPG' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Coal Consumption', // Title of Section on front end
    title: 'Coal Consumption',
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'coal', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Coal', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'coal',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Coal WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'coalWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'kgCO2eCoal' },
      { name: 'Carbon Dioxide Emissions', variableName: 'kgCO2PerUnitCoal' },
      { name: 'Nitros Oxide Emissions', variableName: 'kgN2OPerUnitCoal' },
      { name: 'Methane Emissions', variableName: 'kgCH4PerUnitCoal' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Wood Industrial (Biofuel)', // Title of Section on front end
    title: 'Wood Industrial (Biofuel)', // Title of Section on front end
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'firewood', // Main Section component identifier for tooltips
    version: 2.76,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Wood Industrial (Biofuel)', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'wood-pallet',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Wood Industrial (Biofuel) WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'KG',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'firewoodWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'kgCO2eFirewood' },
      {
        name: 'Carbon Dioxide Emissions',
        variableName: 'kgCO2PerUnitFirewood'
      },
      { name: 'Nitros Oxide Emissions', variableName: 'kgN2OPerUnitFirewood' },
      { name: 'Methane Emissions', variableName: 'kgCH4PerUnitFirewood' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Bioethanol Consumption', // Title of Section on front end
    title: 'Bioethanol Consumption',
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'bioethanol', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Bioethanol', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'bioethanol',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Bioethanol WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'BioethanolWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'bioethanolKgCO2e' },
      { name: 'Carbon Dioxide Emissions', variableName: 'bioethanolKgCO2' },
      { name: 'Nitros Oxide Emissions', variableName: 'bioethanolKgCH4' },
      { name: 'Methane Emissions', variableName: 'bioethanolKgN2O' }
    ]
  },
  {
    dataSource: '',
    dataQuality: '',
    assumptions: '',
    name: 'Biodiesel Consumption', // Title of Section on front end
    title: 'Biodiesel Consumption',
    scope: 1, // scope number
    sectionID: 'fuels', // Default Schema Scope Section Name
    identifier: 'biodiesel', // Main Section component identifier for tooltips
    version: 2.77,
    annually: true,
    emissionSources: [
      {
        category: 'Scope 1 - Category 1: Direct GHG emissions and removals', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Stationary Combustion', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 1, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: '-', // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 1, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Biodiesel', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'default', // default, naturalGasTDL, electricityTDL, WTT
        id: 'biodiesel',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      },
      {
        category: 'Scope 3 - Category 4: Indirect GHG emissions from products used by organization', // ISO category from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        subcategory: 'Fuel & Energy Related Emissions', // Subcategory from Emissions Categories tab on Ekos Internal Calculator BLANK Nov2021 Spreadsheet
        GHGScope: 3, // Customer Data Collection Sheet BLANK Nov2021
        GHGSubcategory: 3, // Customer Data Collection Sheet BLANK Nov2021
        ISOCategory: 4, // Customer Data Collection Sheet BLANK Nov2021
        name: 'Stationary Combustion - Biodiesel WTT', // Emissions source from Customer Data Collection Sheet BLANK Nov2021
        type: 'WTT', // default, naturalGasTDL, electricityTDL, WTT
        id: 'WTT',
        unit: 'L',
        annualTotal: 0,
        tCO2e: 0,
        CO2: 0,
        CH4: 0,
        N2O: 0,
        emissionSourceInfo: {
          dataSource: '',
          dataQuality: 'Please select',
          assumptions: ''
        }
      }
    ],
    extraCalculations: [
      {
        type: 'WTT',
        variableName: 'biodieselWTT' // Main getWellToTankEmissions() variable Name
      }
    ],
    months: [
      {
        month: 'January',
        units: [0]
      },
      {
        month: 'February',
        units: [0]
      },
      {
        month: 'March',
        units: [0]
      },
      {
        month: 'April',
        units: [0]
      },
      {
        month: 'May',
        units: [0]
      },
      {
        month: 'June',
        units: [0]
      },
      {
        month: 'July',
        units: [0]
      },
      {
        month: 'August',
        units: [0]
      },
      {
        month: 'September',
        units: [0]
      },
      {
        month: 'October',
        units: [0]
      },
      {
        month: 'November',
        units: [0]
      },
      {
        month: 'December',
        units: [0]
      }
    ],
    variables: [
      { name: 'Carbon Emissions', variableName: 'biodieselKgCO2e' },
      { name: 'Carbon Dioxide Emissions', variableName: 'biodieselKgCO2' },
      { name: 'Nitros Oxide Emissions', variableName: 'biodieselKgCH4' },
      { name: 'Methane Emissions', variableName: 'biodieselKgN2O' }
    ]
  }
]
export default fuelsSchema

<template>
  <div class="input-section">
    <!-- Modal start -->
    <div class="tooltip">
      <h1 class="section-title">{{ title }}</h1>
      <div class="modal-btn" @click="openModal">
        <i class="fas fa-question-circle"></i>
      </div>
    </div>
    <div class="modal-wrapper" :class="modalOpen ? 'active' : ''">
      <Modal v-model="modalOpen" :tooltip="tooltip" />
    </div>
    <!-- Modal end -->
    <!-- v-show="!schema.annually" -->
    <Controls
      v-on:add="addContractor()"
      v-on:remove="removeContractor()"
      v-on:update="calculateCarbonEmissions()"
    />
    <!-- class="input-container-row tight" -->
    <div
      class="input-container-column"
      v-for="(contractor, index) in schema.contractors"
      :key="`contractor-${index}`"
    >
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Name</label>
          <input
            v-on:change="calculateCarbonEmissions"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model="contractor.values.name"
            :placeholder="contractor.values.name"
            type="text"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Purchased G & S - Electricity KWH</label>
          <input
            v-on:change="() => {
              contractor.values.electricity = contractor.values.electricity || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="contractor.values.electricity"
            :placeholder="contractor.values.electricity"
            type="number" step="0.01"
            name="electricity"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Purchased G & S - Natural gas KWH</label>
          <input
            v-on:change="() => {
              contractor.values.naturalGas = contractor.values.naturalGas || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="contractor.values.naturalGas"
            :placeholder="contractor.values.naturalGas"
            type="number" step="0.01"
            name="naturalGas"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Purchased G & S - Diesel (mobile) L</label>
          <input
            v-on:change="() => {
              contractor.values.diesel = contractor.values.diesel || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="contractor.values.diesel"
            :placeholder="contractor.values.diesel"
            type="number" step="0.01"
            name="diesel"
          />
        </div>
      </div>
      <div class="input-container-row tight">
        <div class="input-item stacked">
          <label>Purchased G & S - Petrol (mobile) L</label>
          <input
            v-on:change="() => {
              contractor.values.petrol = contractor.values.petrol || 0;
              calculateCarbonEmissions()
            }"
            v-on:focus="$root.setEditing()"
            v-on:blur="$root.unsetEditing()"
            v-model.number="contractor.values.petrol"
            :placeholder="contractor.values.petrol"
            type="number" step="0.01"
            name="petrol"
          />
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
</template>

<script>
import Controls from '../../../controls'
import axios from '@/axios'
import Modal from '../../../modal/Modal.vue'

export default {
  name: 'pgs-contracted-services-component',
  props: {
    title: String,
    initialSchema: Object,
    scope3: Boolean,
    schemaName: String,
    activityID: String,
    identifier: String
  },
  components: { Controls, Modal },
  data () {
    return {
      schema: {},
      tooltip: '',
      modalOpen: false
    }
  },
  beforeMount () {
    this.schema = this.initialSchema
    this.getTooltip()
  },
  mounted () {
    // this.calculateCarbonEmissions()
  },

  methods: {
    openModal () {
      this.modalOpen = !this.modalOpen
    },
    calculateCarbonEmissions () {
      var self = this
      return axios
        .post(
          '' + process.env.VUE_APP_APIURL + 'api/calculateScopeThreeContractedServices',
          {
            schema: this.schema,
            annually: self.schema.annually
          }
        )
        .then(function (response) {
          // console.log(response.data.result)
          self.schema = response.data.result
          self.$emit('calculateTotal', response.data.result, self.schemaName)
        })
        .catch(function (error) {
          console.log(error)
        })
        .then(function () {
          self.$root.saveData()
        })
    },
    addContractor () {
      this.schema.contractors.push({
        values: {
          name: `Contractor ${this.schema.contractors.length + 1}`,
          electricity: 0,
          naturalGas: 0,
          diesel: 0,
          petrol: 0
        },
        notes: {
          electricity: '',
          naturalGas: '',
          diesel: '',
          petrol: ''
        }
      })
    },
    removeContractor () {
      if (this.schema.contractors.length > 1) {
        this.schema.contractors.pop()
        this.calculateCarbonEmissions()
      }
    },
    getTooltip () {
      var self = this
      return axios
        .get(
          `${process.env.VUE_APP_APIURL}api/getTooltip/${this.activityID}/${this.identifier}`
        )
        .then(function (response) {
          self.tooltip = response.data.result
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"input-item long"},[_c('label',{staticClass:"input-title",attrs:{"for":"CO2"}},[_vm._v("CO2:")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.source.CO2),expression:"source.CO2",modifiers:{"number":true}}],attrs:{"min":0,"type":"number","step":"0.01","name":"name"},domProps:{"value":(_vm.source.CO2)},on:{"change":function () {
        _vm.source.CO2 = _vm.source.CO2 || 0;
        _vm.$parent.saveSources()
      },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.source, "CO2", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{staticClass:"input-title",attrs:{"for":"CH4"}},[_vm._v("CH4:")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.source.CH4),expression:"source.CH4",modifiers:{"number":true}}],attrs:{"min":0,"type":"number","step":"0.01","name":"name"},domProps:{"value":(_vm.source.CH4)},on:{"change":function () {
        _vm.source.CH4 = _vm.source.CH4 || 0;
        _vm.$parent.saveSources()
      },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.source, "CH4", _vm._n($event.target.value))}}})]),_c('div',{staticClass:"input-item long"},[_c('label',{staticClass:"input-title",attrs:{"for":"N2O"}},[_vm._v("N2O:")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.source.N2O),expression:"source.N2O",modifiers:{"number":true}}],attrs:{"min":0,"type":"number","step":"0.01","name":"name"},domProps:{"value":(_vm.source.N2O)},on:{"change":function () {
        _vm.source.N2O = _vm.source.N2O || 0;
        _vm.$parent.saveSources()
      },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.source, "N2O", _vm._n($event.target.value))}}})]),('HFC' in _vm.source)?_c('div',{staticClass:"input-item long"},[_c('label',{staticClass:"input-title",attrs:{"for":"HFC"}},[_vm._v("HFC:")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.source.HFC),expression:"source.HFC",modifiers:{"number":true}}],attrs:{"min":0,"type":"number","step":"0.01","name":"name"},domProps:{"value":(_vm.source.HFC)},on:{"change":function () {
        _vm.source.HFC = _vm.source.HFC || 0;
        _vm.$parent.saveSources()
      },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.source, "HFC", _vm._n($event.target.value))}}})]):_vm._e(),('PFC' in _vm.source)?_c('div',{staticClass:"input-item long"},[_c('label',{staticClass:"input-title",attrs:{"for":"PFC"}},[_vm._v("PFC:")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.source.PFC),expression:"source.PFC",modifiers:{"number":true}}],attrs:{"min":0,"type":"number","step":"0.01","name":"name"},domProps:{"value":(_vm.source.PFC)},on:{"change":function () {
        _vm.source.PFC = _vm.source.PFC || 0;
        _vm.$parent.saveSources()
      },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.source, "PFC", _vm._n($event.target.value))}}})]):_vm._e(),('SF6' in _vm.source)?_c('div',{staticClass:"input-item long"},[_c('label',{staticClass:"input-title",attrs:{"for":"SF6"}},[_vm._v("SF6:")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.source.SF6),expression:"source.SF6",modifiers:{"number":true}}],attrs:{"min":0,"type":"number","step":"0.01","name":"name"},domProps:{"value":(_vm.source.SF6)},on:{"change":function () {
        _vm.source.SF6 = _vm.source.SF6 || 0;
        _vm.$parent.saveSources()
      },"focus":function($event){return _vm.$root.setEditing()},"blur":[function($event){return _vm.$root.unsetEditing()},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.source, "SF6", _vm._n($event.target.value))}}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }